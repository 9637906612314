<template>
	<div>
		<pui-modal-dialog-form
			:titleText="$t('pollutionalerts.action.missionReport')"
			:modelName="modelName"
			:dialogName="dialogNameMissionReport"
			:widthDialog="1000"
			:onOk="onOkSendMissionReport"
			:onShow="onShowSendMissionReport"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-text-area
							:id="`textarea-pollutionalerts`"
							v-model="data.modalData.textarea"
							:label="$t('pollutionalerts.textarea')"
							:disabled="formDisabled"
							required
							toplabel
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('pollutionalerts.action.close')"
			:modelName="modelName"
			:dialogName="dialogNameClose"
			:widthDialog="1000"
			:componentHeaderName="'pollutionalerts-form-header-modal'"
			:onOk="onOkClose"
			:onShow="onShowClose"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap>
					<v-flex xs6>
						<pui-date-field
							:id="`dateclosure-pollutionalerts`"
							v-model="data.modalData.dateclosure"
							:label="$t('pollutionalerts.dateclosure')"
							toplabel
							time
						></pui-date-field>
					</v-flex>
					<v-flex s12>
						<pui-field-set :title="$t('pollutionalerts.operation')">
							<v-layout wrap style="margin-top: 15px; margin-bottom: 15px">
								<v-flex xs12 md12 lg3 x3>
									<pui-checkbox
										:id="`checkboxhama-${modelName}`"
										:label="$t('pollutionalerts.checkboxhama')"
										v-model="checkboxGroup.checkboxhama"
										true-value="true"
										false-value="false"
									></pui-checkbox>
								</v-flex>
								<v-flex xs12 md12 lg2 x2>
									<pui-checkbox
										:id="`checkboxjmoc-${modelName}`"
										:label="$t('pollutionalerts.checkboxjmoc')"
										v-model="checkboxGroup.checkboxjmoc"
										true-value="true"
										false-value="false"
									></pui-checkbox>
								</v-flex>
								<v-flex xs12 md12 lg3 x3>
									<pui-checkbox
										:id="`checkboxcgof-${modelName}`"
										:label="$t('pollutionalerts.checkboxcgof')"
										v-model="checkboxGroup.checkboxcgof"
										true-value="true"
										false-value="false"
									></pui-checkbox>
								</v-flex>
								<v-flex xs12 md12 lg5 x5>
									<pui-checkbox
										:id="`checkboxbsco-${modelName}`"
										:label="$t('pollutionalerts.checkboxbsco')"
										v-model="checkboxGroup.checkboxbsco"
										true-value="true"
										false-value="false"
									></pui-checkbox
								></v-flex>
								<v-flex xs12 md12 lg2 x2
									><pui-checkbox
										:id="`checkboxvtso-${modelName}`"
										:label="$t('pollutionalerts.checkboxvtso')"
										v-model="checkboxGroup.checkboxvtso"
										true-value="true"
										false-value="false"
									></pui-checkbox
								></v-flex>
							</v-layout>
						</pui-field-set>
					</v-flex>
					<v-flex xs12>
						<pui-text-area
							:id="`incidentreport-pollutionalerts`"
							v-model="data.modalData.incidentreport"
							:label="$t('pollutionalerts.incidentreport')"
							:disabled="formDisabled"
							required
							toplabel
						></pui-text-area>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'pollutionalerts-modals',
	data() {
		return {
			dialogNameMissionReport: 'missionReport',
			dialogNameClose: 'close',
			checkboxGroup: {
				checkboxhama: false,
				checkboxbsco: false,
				checkboxvtso: false,
				checkboxcgof: false,
				checkboxjmoc: false
			}
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onOkSendMissionReport(modalData) {
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					this.$store.getters.getModelByName('pollutionalerts').url.missionreport,
					{
						pollutionid: modalData.headerPk.pollutionid,
						missionreport: modalData.textarea
					},
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), this.$puiI18n.t('pollutionalerts.action.missionReport'));
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiNotify.error(message, this.$puiI18n.t('pollutionalerts.action.missionReport', 0, true));
						resolve(false);
					}
				);
			});
		},
		onShowSendMissionReport(modalData) {
			modalData.textarea = null;
		},
		onShowClose(modalData) {
			modalData.incidentreport = null;
		},
		onOkClose(modalData) {
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					this.$store.getters.getModelByName('pollutionalerts').url.incidentreport,
					{
						pollutionid: modalData.headerPk.pollutionid,
						incidentreport: modalData.incidentreport,
						dateClosure: modalData.dateclosure,
						hama: this.checkboxGroup.checkboxhama,
						jmoc: this.checkboxGroup.checkboxjmoc,
						cgof: this.checkboxGroup.checkboxcgof,
						bsco: this.checkboxGroup.checkboxbsco,
						vtso: this.checkboxGroup.checkboxvtso
					},
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), this.$puiI18n.t('pollutionalerts.incidentreport'));
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiNotify.error(message, this.$puiI18n.t('pollutionalerts.incidentreport', 0, true));
						resolve(false);
					}
				);
			});
		}
	}
};
</script>
