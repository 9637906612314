<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:readOnly="readOnly"
			:externalFilter="externalFilter"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modelColumnDefs="modelColumnDefs"
			:readOnly="readOnly"
			:modalDialog="modalDialog"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
		<pollutionalerts-modals :modelName="modelName"></pollutionalerts-modals>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import pollutionalertsActions from './PollutionalertsActions';
import pollutionalertsModals from './PollutionalertsModals.vue';

export default {
	name: 'pollutionalerts-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		'pollutionalerts-modals': pollutionalertsModals
	},
	data() {
		return {
			modelName: 'pollutionalerts',
			actions: pollutionalertsActions.gridactions,
			modelColumnDefs: {
				incidentreport: {
					render: (data) => {
						if (data === 'Submitted') {
							return this.$t('grid.portcalls.statuslist.status.S');
						} else if (data === 'Pending') {
							return this.$t('grid.portcalls.statuslist.status.P');
						} else {
							return '';
						}
					}
				},
				missionreport: {
					render: (data) => {
						if (data === 'Submitted') {
							return this.$t('grid.portcalls.statuslist.status.S');
						} else if (data === 'Pending') {
							return this.$t('grid.portcalls.statuslist.status.P');
						} else {
							return '';
						}
					}
				}
			}
		};
	}
};
</script>
