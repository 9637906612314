import { DURACION } from '../constants/notification/NotificationConstant';

export default {
	notifyError(title, message) {
		const messageEvent = message || this.$puiI18n.t('puiaction.notifyError');
		const titleEvent = this.$puiI18n.t('puiaction.notifyTitle') + (title ? ' > ' + title : '');
		this.$puiNotify.error(messageEvent, titleEvent, DURACION, true);
	},
	notifySuccess(title, message) {
		const messageEvent = message || this.$puiI18n.t('puiaction.notifySuccess');
		const titleEvent = this.$puiI18n.t('puiaction.notifyTitle') + (title ? ' > ' + title : '');
		this.$puiNotify.success(messageEvent, titleEvent);
	},
	getPkFromModel(model, registry) {
		const pk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				pk[column.name] = registry[column.name];
			}
		}
		return pk;
	}
};
