import utilActions from '@/api/actions';
import printReportAction from '@/components/nsw-components/PrintReportsAction';

const missionReport = {
	id: 'missionReport',
	selectionType: 'single',
	label: 'pollutionalerts.action.missionReport',
	functionality: 'ACT_POLLUTIONALERTS_MISSIONREPORT',
	checkAvailability: function (row) {
		return row[0].missionreport == 'Pending' || row[0].missionreportform == 'Pending';
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-missionReport-' + model.name + '-show', row);
	}
};

const close = {
	id: 'close',
	selectionType: 'single',
	label: 'pollutionalerts.action.close',
	functionality: 'ACT_POLLUTIONALERTS_CLOSE',
	checkAvailability: function (row) {
		return (
			(row[0].missionreport == 'Submitted' && row[0].statusdesc == 'Open') ||
			(row[0].missionreportform == 'Submitted' && row[0].statuscode == 'O')
		);
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-close-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [missionReport, close, printReportAction.printReport('POLLUTION_REPORT', 'pollutionid', 'reports.label.pollution.exportpdf')]
};
