import Pui9HttpRequests from '@Pui9Requests';

/**
 * reportType: the type of the report
 * idAttributeName: the name of the ID attribute (imofalformid)
 * actionLabel: action label
 */
const printReport = (reportType, idAttributeName, actionLabel) => {
	return {
		id: 'PRINT_REPORT',
		selectionType: 'single',
		label: actionLabel,
		functionality: null,
		checkAvailability: function (registries) {
			return registries;
		},
		runAction: async function (action, model, registries) {
			const title = this.$puiI18n.t('reports.title');
			const id = registries[0][idAttributeName];

			const params = {
				type: reportType,
				id: id
			};

			var resp;
			await Pui9HttpRequests.downloadFileRequest(
				'get',
				'/reports/printReport',
				params,
				(response) => {
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(new window.Blob([response.data]));
					const fileNameMatch = response.headers['content-disposition'].match(/filename="(.+)"/);

					link.download = fileNameMatch[0].slice(10).substring(0, fileNameMatch[0].slice(10).length - 1);

					link.click();
					if (response) {
						response.success = true;
						resp = response;
					}
				},
				(error) => {
					if (error) {
						error.error = true;
						resp = error;
					}
				}
			);

			if (resp.error) {
				this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				const message = this.$puiI18n.t('puiaction.notifyError');
				this.$puiNotify.error(message, title, true);
			} else {
				this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				const message = this.$puiI18n.t('puiaction.notifySuccess');
				this.$puiNotify.success(message, title);
			}
		}
	};
};

export default {
	printReport
};
